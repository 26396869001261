<template>
  <div class="box">
    <div class="banner">
      <div class="box">
        <div class="left">
          <img src="/img/zhuceleft.png" alt="" />
        </div>
        <div class="right">
          <div class="login-title">
            <div :class="{ 'login-title-item': true, active: loginTitle === 'vc' }" @click="onClickLoginTitle('vc')">
              验证码登录
            </div>
            <div :class="{ 'login-title-item': true, active: loginTitle === 'password' }" @click="onClickLoginTitle('password')">
              密码登录
            </div>
          </div>
          <p class="title-font">
            用户登录
          </p>
          <el-form v-if="loginTitle === 'password'" :rules="rules" ref="form" :model="form" class="body-from">
            <el-form-item prop="userNameOrEmailAddress">
              <el-input placeholder="请输入手机号" v-model="form.userNameOrEmailAddress"></el-input>
            </el-form-item>
            <el-form-item prop="password">
              <el-input placeholder="请输入密码" v-model="form.password" show-password></el-input>
            </el-form-item>
            <p>
              <el-radio-group v-model="form.userType" @change="onChange" class="from-radio">
                <el-radio :label="option.val" v-for="option in userTypeOptions" :key="option.val">{{ option.label }}
                </el-radio>
              </el-radio-group>
            </p>
            <el-form-item>
              <el-button @click="reqLogin" class="submit-btn">登录</el-button>
              <el-button @click="goHome" class="cancle-btn">返回</el-button>
            </el-form-item>
            <el-form-item>
              <a href="/register/forgetPassword">忘记密码</a>
            </el-form-item>
          </el-form>
          <el-form v-if="loginTitle === 'vc'" :rules="rules" ref="form" :model="form" class="body-from">
            <el-form-item>
              <el-input placeholder="请输入手机号" v-model="phone"></el-input>
            </el-form-item>
            <el-form-item>
              <el-col :span="16">
                <el-input placeholder="请输入验证码" v-model="phonenum"></el-input>
              </el-col>
              <el-col :offset="1" :span="7">
                <el-button style="width: 100%; color: rgb(81, 85, 92)" :disabled="loginVcTime < defaultTimeOutNum" @click="onClickGetVc">{{loginVcTime < defaultTimeOutNum ? loginVcTime : '获取验证码'}}</el-button>
              </el-col>
            </el-form-item>
            <p>
              <el-radio-group v-model="form.userType" @change="onChange" class="from-radio">
                <el-radio :label="option.val" v-for="option in userTypeOptions" :key="option.val">{{ option.label }}
                </el-radio>
              </el-radio-group>
            </p>
            <el-form-item>
              <el-button @click="numlogin" class="submit-btn">登录</el-button>
              <el-button @click="goHome" class="cancle-btn">返回</el-button>
            </el-form-item>
            <el-form-item>
              <a href="/register/forgetPassword">忘记密码</a>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import request from "@/utils/request";
import Cookies from "js-cookie";
import { ajax } from "@/utils/ajax";

const defaultTimeOutNum = 60

export default {
  name: "login",
  data() {
    const validTel = (rule, val, cb) => {
      const exp = /^1[3|4|5|8][0-9]\d{4,8}$/;
      if (!val) cb(new Error("请输入手机号"));
      // else if (!exp.test(val)) cb(new Error('手机号码格式有误'));
      else cb();
    };
    return {
      phone: '',
      phonenum: '',
      userTypeId: 2,
      form: {
        userNameOrEmailAddress: "",
        password: "",
        userType: "2",
      },
      rules: {
        userNameOrEmailAddress: [
          {
            required: true,
            trigger: "blur",
            validator: validTel,
          },
        ],
        address: [
          {
            required: true,
            message: "请选择地址",
            trigger: "blur",
          },
        ],
      },
      userTypeOptions: [
        // { label: "后台用户", val: "0" },
        {
          label: "企业用户",
          val: "1",
        },
        {
          label: "车主用户",
          val: "2",
        },
      ],
      loginTitle: 'vc',
      loginVcTime: defaultTimeOutNum,
      defaultTimeOutNum,
      timeOut: null,
      resCount: 0,
      checked: false,
    };
  },
  created() {
    let that = this;
    document.onkeydown = function (e) {
      let key = window.event.keyCode;
      if (key == 13) {
        that.reqLogin();
      }
    };
  },
  methods: {
    onClickGetVc() {
      if (this.phone == '') {
        this.$message.error("请输入手机号");
        return false
      }
      const reg = /^1[34578]\d{9}$/;
      if (!reg.test(this.phone)) {
        this.$message.error("请输入正确的手机号");
        return false
      }
      const that = this
      ajax.get(
        '/TokenAuth/SeedLoginNumber', {
        phone: that.phone
      }).then(res => {
        if (res.result.success) {
          that.timeOut = setInterval(() => {
            if (that.loginVcTime <= 0) {
              clearInterval(that.timeOut)
              that.loginVcTime = defaultTimeOutNum
            } else {
              --that.loginVcTime
            }
          }, 500)
        }else{
          this.$message.error(res.result.msg);
        }
      })
    },
    numlogin() {
      if (this.phone == "") {
        this.$message.error("请输入手机号");
        return;
      }
      if (this.phonenum == "") {
        this.$message.error("请输入验证码");
        return;
      }
      let loginJson = {
        userNameOrEmailAddress: this.phone,
        password: this.phonenum,
        rememberClient: true,
        userType: this.userTypeId,
      };
      this.getData();
      this.getuserlogin(loginJson, '/TokenAuth/SeedLogin');
    },
    onClickLoginTitle(titleCode) {
      if (titleCode === 'password' && this.loginTitle === 'vc') {
        clearInterval(this.timeOut)
        this.loginVcTime = defaultTimeOutNum
      }
      if (this.loginTitle !== titleCode) {
        this.loginTitle = titleCode
      }
    },
    getData() {
      const loading = this.$loading({
        lock: true, //lock的修改符--默认是false
        text: "Loading", //显示在加载图标下方的加载文案
        spinner: "el-icon-loading", //自定义加载图标类名
        background: "rgba(0, 0, 0, 0.7)", //遮罩层颜色
        target: document.querySelector("#table"), //loadin覆盖的dom元素节点
      });
      //成功回调函数停止加载
      loading.close();
    },
    // 登录
    async getuserlogin(obj, url) {
      request({
        url: url,
        method: "post",
        data: obj,
      })
        .then((response) => {
          if (response.result.isLogin == true) {
            const userInfo = {
              name: response.result.name,
              userId: response.result.userId,
              userPhoto: response.result.filePath,
              userType: this.form.userType,
            };
            window.sessionStorage.setItem("token", response.result.accessToken);
            window.sessionStorage.setItem("isLogin", true);
            window.sessionStorage.setItem("userId", response.result.userId);
            window.sessionStorage.setItem("name", response.result.name);
            window.sessionStorage.setItem(
              "userPhoto",
              response.result.filePath
            );
            window.sessionStorage.setItem("userInfo", JSON.stringify(userInfo));
            //   // 定义清楚cookie的时间
            let inFifteenMinutes = new Date(
              new Date().getTime() + 24 * 60 * 60 * 1000
            );
            this.$store.dispatch("getShoppingCartCount");
            Cookies.set("userInfo", userInfo, {
              expires: inFifteenMinutes,
            });
            Cookies.set("token", response.result.accessToken, {
              expires: inFifteenMinutes,
            });
            this.$message.success("登录成功");
            this.$router.push('/home');
          } else {
            this.$message.error(response.result.msg);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    onChange(val) {
      console.log(val);
      this.userTypeId = val;
    },
    async reqLogin() {
      if (this.form.userNameOrEmailAddress == "") {
        this.$message("请输入账号");
        return;
      }
      if (this.form.password == "") {
        this.$message("请输入密码");
        return;
      }
      console.log(this.userTypeId);
      let loginJson = {
        userNameOrEmailAddress: this.form.userNameOrEmailAddress,
        password: this.form.password,
        rememberClient: true,
        userType: this.userTypeId,
      };
      this.getData();
      this.getuserlogin(loginJson, "/TokenAuth/Authenticate");
    },
    goHome() {
      this.$router.push("/");
    },
    async jump() {
      try {
        const valid = await this.$refs["form"].validate();
        if (valid) {
          const res = await this.$store.dispatch("user/login", this.form);
          console.log(res);
          if (res) {
            this.$router.push({
              path: "/",
            });
          }
        } else return false;
      } catch (error) {
        console.log(error);
      }
    },
    forgetPassword() {
      this.$router.push({
        path: "/register/forgetPassword",
      });
    },
  },
};
</script>

<style lang="less" scoped>
.body-from {
  padding: 29px 30px 30px 30px; /* no */
}
.login-title {
  display: flex;
  line-height: 54px;
  border-bottom: 1px solid rgb(220, 223, 230);
  color: rgb(120, 120, 121);
  .login-title-item {
    flex: 1;
    text-align: center;
    font-size: 18px;
    cursor: pointer;
    border-bottom: 1px solid rgb(220, 223, 230);
    border-right: 1px solid rgb(220, 223, 230);
    &:last-child {
      border-right: none;
    }
    &.active {
      color: #000;
    }
  }
}
.from-radio {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px; /* no */
  padding: 0 10px; /* no */
}
.submit-btn {
  background: #24a2aa;
  color: white;
  width: 45%;
  height: 40px; /* no */
  margin-right: 10px; /* no */
}
.cancle-btn {
  width: 45%;
  height: 40px; /* no */
}
.banner {
  background-image: url(/img/zhucebanner.png);
  width: 100%;
  height: 720px; /* no */
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  .title-font {
    font-size: 24px; /* no */
    font-family: Source Han Sans CN;
    font-weight: bold;
    color: #24a2aa;
    text-align: center;
    letter-spacing: 10px; /* no */
    padding-top: 20px; /* no */
  }
  .box {
    width: 49.22%;
    height: 78.14%;
    display: flex;
    background-color: #f8fcff;
    .left {
      width: 55%;
      height: 100%;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .right {
      width: 45%;
      height: 100%;
      background: white;
      border-radius: 0 16px 16px 0; /* no */
    }
  }
}

/deep/.el-radio__input.is-checked .el-radio__inner {
  border-color: #24a2aa;
  background: #24a2aa;
}
</style>
